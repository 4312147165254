import { gql } from '@apollo/client';

export const SHOPPING_LIST_V2 = gql`
    query ShoppingListV2($shoppingListV2Id: String!) {
        shoppingListV2(id: $shoppingListV2Id) {
            id
            lineItemsCount
            name
            lastModifiedAt
            slug
            shareState
            createdBy {
                contactId
                name
            }
            lineItems {
                bonus {
                    isTecselect
                    isAbakus
                    isAbakusPlus
                    isPromotion
                    isTopProduct
                }
                categories {
                    id
                    externalId
                    slug
                    name
                    description
                    stagedProductCount
                    orderHint
                    parent {
                        id
                    }
                    custom {
                        customFieldsRaw {
                            name
                            value
                        }
                    }
                    metaTitle
                    metaDescription
                }
                customerArticleNumber
                ean
                hasAccessories
                hasAlternatives
                id
                thumbnail
                packagingSize
                procuredProduct
                productCocontractor
                productName
                quantityUnit
                salesUnit
                sku
                supplierId
                supplierProductNumber
                isOnlineAvailable
                reachInfo
                relationType
                weightReference
                scoreCardIndex
                isUnqualifiedContractProduct
                productId
                supplierName
                quantity
                addedBy {
                    contactId
                    name
                }
                chargeNr
                positionText
                position
            }
        }
    }
`;
