import { FEATURE_STATE, useIsFeatureVisible } from '@ab-core/functions';

enum SEARCH_VERSION {
    V1 = 'v1',
    V2 = 'v2'
}

export enum V2_HELPER_STATE {
    LOADING = 'loading',
    ACTIVE = 'active',
    INACTIVE = 'inactive'
}

export const useIsSearchV2 = () => {
    const { isFeatureVisible } = useIsFeatureVisible();

    const userHasFeature = () => isFeatureVisible('factFinder');
    const isSearchV2 = () => {
        const isFeature = userHasFeature();

        if (!isFeature) {
            return false;
        }

        const searchVersion = getSearchVersion();

        if (searchVersion === SEARCH_VERSION.V1) {
            return false;
        }

        return true;
    };

    return { isSearchV2, userHasFeature };
};

export const useIsSearchV2OrLoading = () => {
    const { isFeatureVisibleOrLoading } = useIsFeatureVisible();

    const userHasFeature = () => isFeatureVisibleOrLoading('factFinder');
    const isSearchV2 = () => {
        const isFeature = userHasFeature();

        if (isFeature === FEATURE_STATE.LOADING) {
            return V2_HELPER_STATE.LOADING;
        }

        if (isFeature === FEATURE_STATE.DENIED) {
            return V2_HELPER_STATE.INACTIVE;
        }

        const searchVersion = getSearchVersion();

        if (searchVersion === SEARCH_VERSION.V1) {
            return V2_HELPER_STATE.INACTIVE;
        }

        return V2_HELPER_STATE.ACTIVE;
    };

    return { isSearchV2 };
};

export const useGetSearchQueryString = () => {
    const { isSearchV2 } = useIsSearchV2();
    const isV2 = isSearchV2();

    if (isV2) {
        return 'query';
    }

    return 'q';
};

const TOGGLE_SEARCH_KEY = 'search_version';

export const toggleSearchVersion = () => {
    const searchVersion = getSearchVersion();

    if (searchVersion === SEARCH_VERSION.V2) {
        localStorage.setItem(TOGGLE_SEARCH_KEY, SEARCH_VERSION.V1);

        return;
    }

    localStorage.setItem(TOGGLE_SEARCH_KEY, SEARCH_VERSION.V2);
};

const getSearchVersion = () => {
    const toggleSearchItem = localStorage.getItem(TOGGLE_SEARCH_KEY);

    if (toggleSearchItem === SEARCH_VERSION.V1) {
        return SEARCH_VERSION.V1;
    }

    return SEARCH_VERSION.V2;
};
